@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  .text-stroke {
    text-shadow:
    -2px -2px 0 #fff,  
      2cap -2px 0 #fff,
      -2px 2px 0 #fff,
      2px 2px 0 #fff;
  }
}